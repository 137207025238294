
import { mapState } from 'vuex'

export default {
    name: 'Alert',

    computed: {
        alert: {
            set(value) {
                this.$store.commit('alert/setAlert', value)
            },
            get() {
                return this.$store.state.alert.alert
            },
        },

        ...mapState('alert', ['alertMessage']),
    },
}
